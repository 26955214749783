import React from 'react';
import { faGithub, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import './Sidebar.css';

const Sidebar = () => {
	return (
		<div className="sidebar-sticky">
			<Nav.Link target="_blank" href="https://www.linkedin.com/in/doohwankim/">
				<FontAwesomeIcon icon={faLinkedin} size="lg" />
			</Nav.Link>
			<Nav.Link target="_blank" href="https://github.com/DooHwanKim0419">
				<FontAwesomeIcon icon={faGithub} size="lg" />
			</Nav.Link>
			<Nav.Link target="_blank" href="mailto:dkimh0419@gmail.com">
				<FontAwesomeIcon icon={faGoogle} size="lg" />
			</Nav.Link>
			<hr />
		</div>
	);
};

export default Sidebar;
