import React from 'react';
import { Button, Nav } from 'react-bootstrap';
import profile from '../statics/profile.jpeg';
import './Hero.css';

const Hero = () => {
	return (
		<div className="hero-container">
			<div>
				<div className="background-image">
					<img src={profile} />
				</div>
				<div className="hero-intro">
					<div>
						<h1>DooHwan Kim</h1>
						<Nav.Link
							target="_blank"
							href="https://drive.google.com/file/d/14GPVo6x2Oq7W-SMZunHTlBTbllzHNMCS/view?usp=sharing">
							<Button variant="outline-info">Resume</Button>
						</Nav.Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
