import React from 'react';
import './Experiences.css';

const Experiences = () => {
	return (
		<div id="experience" className="experience-container">
			<h1>Professional Experiences</h1>
			<div className="experience active-perception-lab">
				<h1>01</h1>
				<h6>University of Rochester - Active Perception Lab</h6>
				<div className="experience-title">
					<h6>Undergraduate Software Engineer</h6>
					<h6>Jan 2023 - May 2023</h6>
				</div>
				<div className="experience-info">
					Contributed to the development of scalable software by conducting extensive codebase refactoring,
					eliminating redundancies, and enhanced the user interface of the current Angular-based software used
					in the lab
				</div>
			</div>
			<div className="experience marketit-experience">
				<h1>02</h1>
				<h6>Marketit</h6>
				<div className="experience-title">
					<h6>Software Engineer</h6>
					<h6>Sep 2020 - Aug 2022</h6>
				</div>
				<div className="experience-info">
					As a Korean citizen, I had the unique opportunity to fulfill my national duty differently by serving
					as a front-end software engineer at South Korean startup Marketit for two years, instead of
					completing mandatory military service. I played a pivotal role in developing and maintaining various
					projects, honing my expertise in languages like Typescript, Javascript, HTML, CSS, and Python, as
					well as mastering frameworks like ReactJS and AngularJS. My exceptional work led to a six-month
					promotion as a project leader, managing a team of seven developers and successfully delivering a
					critical project for Hyundai Card through effective collaboration and problem-solving with various
					departments, culminating in a resounding success.
				</div>
			</div>
			<h1>Academic Experiences</h1>
			<div className="experience ta3">
				<h1>03</h1>
				<h6>University of Rochester</h6>
				<div className="experience-title">
					<h6>Teaching Assistant – Computation and Formal Systems</h6>
					<h6>Jan 2023 - May 2023</h6>
				</div>
				<div className="experience-info">
					Assisting Professor George Ferguson in his class of over 80 students by grading projects and
					preparing for study sessions was an enriching experience that allowed me to actively engage in the
					students' academic journey. In this role, I provided valuable feedback on their projects,
					contributing to their learning and growth. Moreover, this collaborative effort highlighted the
					significance of teamwork in creating a vibrant and effective educational atmosphere, where both
					students and instructors played pivotal roles in the quest for knowledge and academic excellence.
				</div>
			</div>
			<div className="experience ta4">
				<h1>04</h1>
				<h6>University of Rochester</h6>
				<div className="experience-title">
					<h6>Teaching Assistant – Introduction to the Artificial Intelligence</h6>
					<h6>Sep 2022 - Dec 2022</h6>
				</div>
				<div className="experience-info">
					In my role as an assistant to Professor George Ferguson, I played a pivotal role in the education of
					a class exceeding 100 students. Grading assignments and facilitating study sessions were among my
					primary responsibilities, ensuring that students received valuable feedback and opportunities for
					collaborative learning. Furthermore, I hosted weekly AI theory-focused study sessions for over 10
					students, providing a platform for in-depth exploration of the subject matter and the chance to
					address intricate questions. This experience allowed me to contribute significantly to both the
					large-class environment and smaller, more personalized group interactions, enhancing the educational
					experience for students while expanding my own knowledge of artificial intelligence.
				</div>
			</div>
			<div className="experience ta5">
				<h1>05</h1>
				<h6>University of Rochester</h6>
				<div className="experience-title">
					<h6>Teaching Assistant – Mobile App Development</h6>
					<h6>Jan 2020 - May 2020</h6>
				</div>
				<div className="experience-info">
					As an assistant in Professor Arthur Roolfs' class of over 40 students, I played a pivotal role in
					grading assignments and addressing student inquiries promptly. This experience deepened my
					understanding of the subject matter and reinforced the importance of effective communication in
					fostering a positive learning environment. It was a valuable and enriching experience both
					professionally and personally.
				</div>
			</div>
		</div>
	);
};

export default Experiences;
