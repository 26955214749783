import React from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<div className="footer-container">
			<h6>DooHwan Kim's Personal Portfolio Website</h6>
			<h6>(Currently not supporting mobile devices)</h6>
		</div>
	);
};

export default Footer;
