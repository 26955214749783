import React from 'react';
// @ts-ignore
import AnimatedCursor from 'react-animated-cursor';
import './App.css';
import Contents from './Contents/Contents';
import Hero from './Hero/Hero';
import Navigation from './Navigation/Navigation';
import Sidebar from './Sidebar/Sidebar';

function App() {
	return (
		<div className="App">
			<AnimatedCursor
				innserSize={20}
				outerSize={35}
				color="255, 255, 255"
				outerAlpha={0.4}
				innerScale={0.7}
				outerScale={5}
			/>
			<div className="main-container">
				<Navigation />
				<Hero />
				<div className="sidebar-area">
					<div className="sidebar">
						<Sidebar />
					</div>
					<div className="sections">
						<Contents />
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
