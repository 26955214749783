import React from 'react';
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import './Navigation.css';

const bounceAnimation = keyframes`${bounce}`;
const BoundFirstLink = styled.a`
	animation: 1s ${bounceAnimation};
`;

const BoundSecondLink = styled.a`
	animation: 3s ${bounceAnimation};
`;

const BoundThirdLink = styled.a`
	animation: 5s ${bounceAnimation};
`;

const Navigation = () => {
	return (
		<div className="navigation">
			<div className="nav-section">
				<BoundFirstLink href="#about">About</BoundFirstLink>
				<BoundSecondLink href="#experience">Experiences</BoundSecondLink>
				<BoundThirdLink href="#contact">Contact</BoundThirdLink>
			</div>
		</div>
	);
};

export default Navigation;
