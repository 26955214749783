import React from 'react';
import About from './About/About';
import Contact from './Contact/Contact';
import Experiences from './Experiences/Experiences';
import Education from './Education/Education';
import Footer from './Footer/Footer';
import './Contents.css';

const Contents = () => {
	return (
		<div className="contents">
			<About />
			<Education />
			<Experiences />
			<Contact />
			<Footer />
		</div>
	);
};

export default Contents;
