import React from 'react';
import { Nav } from 'react-bootstrap';
import './Contact.css';

const Contact = () => {
	return (
		<div id="contact" className="contact-container">
			<h1>Contact</h1>
			<hr />
			<div>
				<Nav.Link target="_blank" href="https://www.linkedin.com/in/doohwankim/">
					LINKEDIN
				</Nav.Link>
				<Nav.Link target="_blank" href="https://github.com/DooHwanKim0419">
					GITHUB
				</Nav.Link>
				<Nav.Link target="_blank" href="mailto:dkimh0419@gmail.com">
					EMAIL
				</Nav.Link>
			</div>
		</div>
	);
};

export default Contact;
