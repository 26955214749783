import React from 'react';
import TypeWriter from 'typewriter-effect';
import './Education.css';

const Education = () => {
	return (
		<div className="education-container">
			<h1>Education</h1>
			<div className="education-wrapper">
				<div className="institution-info">
					<h5>2023-2024</h5>
					<h6>Northwestern University</h6>
				</div>
				<div className="institution-description">
					<TypeWriter
						options={{
							strings: ['Currently Pursuing', 'Master of Science in Computer Science'],
							autoStart: true,
							loop: true,
							deleteSpeed: 100,
						}}
					/>
				</div>
			</div>
			<div className="education-wrapper">
				<div className="institution-info">
					<h5>2017-2023</h5>
					<h6>University of Rochester</h6>
				</div>
				<div className="institution-description">Bacherlor of Science in Computer Science</div>
			</div>
		</div>
	);
};

export default Education;
