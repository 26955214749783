import React from 'react';
import './About.css';

const About = () => {
	return (
		<div id="about" className="about-container">
			<h1>About Me.</h1>
			<div>
				<p>
					I'm DooHwan, a passionate computer science student currently pursuing my Master's degree at
					Northwestern University. My journey in software development began during my undergraduate studies at
					the University of Rochester, where I honed my skills in various software technologies. As a teaching
					assistant for multiple courses, I've cultivated the ability to guide fellow students and facilitate
					a collaborative learning environment. Additionally, I bring two years of professional experience as
					a software engineer at Marketit, where I managed projects, ensuring their successful and timely
					delivery. With a strong academic background and practical experience, I'm well-prepared to
					contribute to any software development team.
				</p>
			</div>
		</div>
	);
};

export default About;
